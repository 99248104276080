// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-3-displaydemo-tsx": () => import("./../../../src/pages/3displaydemo.tsx" /* webpackChunkName: "component---src-pages-3-displaydemo-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cityset-tsx": () => import("./../../../src/pages/cityset.tsx" /* webpackChunkName: "component---src-pages-cityset-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dialog-details-js": () => import("./../../../src/pages/DialogDetails.js" /* webpackChunkName: "component---src-pages-dialog-details-js" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-localstragetest-js": () => import("./../../../src/pages/localstragetest.js" /* webpackChunkName: "component---src-pages-localstragetest-js" */),
  "component---src-pages-mdlist-tsx": () => import("./../../../src/pages/mdlist.tsx" /* webpackChunkName: "component---src-pages-mdlist-tsx" */),
  "component---src-pages-sdgs-tsx": () => import("./../../../src/pages/sdgs.tsx" /* webpackChunkName: "component---src-pages-sdgs-tsx" */),
  "component---src-pages-sdgstheme-tsx": () => import("./../../../src/pages/sdgstheme.tsx" /* webpackChunkName: "component---src-pages-sdgstheme-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-themeset-tsx": () => import("./../../../src/pages/themeset.tsx" /* webpackChunkName: "component---src-pages-themeset-tsx" */),
  "component---src-pages-top-js": () => import("./../../../src/pages/top.js" /* webpackChunkName: "component---src-pages-top-js" */),
  "component---src-pages-whatsnew-tsx": () => import("./../../../src/pages/whatsnew.tsx" /* webpackChunkName: "component---src-pages-whatsnew-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

